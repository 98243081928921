import SelectSex from '../../components/buttons/SelectSex'
import PropTypes from 'prop-types'
import style from '../../scss/pages/PublicTontinatorPage.module.scss'
import TontinatorInputs from '../../components/mytontine-dashboard/TontinatorInputs'
import { useTranslate } from '../../hooks/useTranslate'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { EVENT_DESC } from '../../analytics/EventDescription'
import StaticToolTip from '../../components/feedback/StaticToolTip'

/**
 * Extended TontinatorInputs with `SelectSex` component
 */
const PublicTontinatorInputs = ({
  incomeForecastParams,
  setIncomeForecastParams,
  comparison,
  blueForecastParams,
  setBlueForecastParams,
  yellowForecastParams,
  setYellowForecastParams,
}) => {
  const t = useTranslate()

  return (
    <section className={style['public-tontinator-page__input-layout']}>
      {comparison ? (
        // Sliders for the comparison page
        <>
          <div className={style['public-tontinator-page__slider-group']}>
            <StaticToolTip text={t('PLAN1_INPUT_GROUP')} color="blue" />
          </div>
          <TontinatorInputs
            formData={blueForecastParams}
            contributionSliders
            retirementSliders
            hideCurrentAgeSlider
            setFormData={setBlueForecastParams}
            forceDisableRetAgeDecrement={
              blueForecastParams?.retirementAge ===
              blueForecastParams?.contributionAge
            }
            trackingActivityRetirementSlider={{
              incBtnId: 'plan1_slider_increment_income_start_age',
              decBtnId: 'plan1_slider_decrement_income_start_age',

              incBtnBoxId: 'plan1_slider_increment_income_start_age_box',
            }}
            trackActivityOneTimeContribution={{
              incBtnId: 'plan1_slider_increment_onetime_contribution',

              decBtnId: 'plan1_slider_decrement_onetime_contribution',

              incBtnBoxId: 'plan1_slider_increment_onetime_box',
            }}
            trackRetAgeRangeActivity={{
              sliderId: 'plan1_slider_income_start_age',
            }}
            trackRangeOneTime={{
              sliderId: 'plan1_slider_onetime_contribution',
            }}
            hideTargetInput
          />

          <div className={style['public-tontinator-page__slider-group']}>
            <StaticToolTip text={t('PLAN2_INPUT_GROUP')} color="gold" />
          </div>
          <TontinatorInputs
            formData={yellowForecastParams}
            contributionSliders
            retirementSliders
            hideCurrentAgeSlider
            setFormData={setYellowForecastParams}
            sliderVariant={'yellow'}
            forceDisableRetAgeDecrement={
              yellowForecastParams?.retirementAge ===
              yellowForecastParams?.contributionAge
            }
            p2RetAgeSliderBoxTestId={UI_TEST_ID.p2RetirementAgeSliderBox}
            p2RetAgeSliderIncrementTestId={
              UI_TEST_ID.p2RetirementAgeIncrementButton
            }
            p2RetAgeSliderDecrementTestId={
              UI_TEST_ID.p2RetirementAgeDecrementButton
            }
            p2OneTimeSliderBoxTestId={UI_TEST_ID.p2OneTimeRetirementSliderBox}
            p2OneTimeIncrementButtonTestId={UI_TEST_ID.p2OneTimeIncrementButton}
            p2OneTimeDecrementButtonTestId={UI_TEST_ID.p2OneTimeDecrementButton}
            trackingActivityRetirementSlider={{
              incBtnId: 'plan2_slider_increment_income_start_age',
              decBtnId: 'plan2_slider_decrement_income_start_age',
              incBtnBoxId: 'plan2_slider_increment_income_start_age_box',
            }}
            trackActivityOneTimeContribution={{
              incBtnId: 'plan2_slider_increment_onetime_contribution',
              decBtnId: 'plan2_slider_decrement_onetime_contribution',
              incBtnBoxId: 'plan2_slider_increment_onetime_box',
            }}
            trackRetAgeRangeActivity={{
              sliderId: 'plan2_slider_income_start_age',
            }}
            trackRangeOneTime={{
              sliderId: 'plan2_slider_onetime_contribution',
            }}
            hideTargetInput
          />
        </>
      ) : (
        // Sliders for the TONTINATOR PAGE
        <>
          <SelectSex
            sex={incomeForecastParams?.sex}
            setSex={(sex) => {
              setIncomeForecastParams((prevValues) => ({
                ...prevValues,
                sex,
              }))
            }}
            trackActivities={[
              {
                trackId: 'tontinator_sex_male',
                eventDescription: EVENT_DESC.tontinatorSex,
              },
              {
                trackId: 'tontinator_sex_female',
                eventDescription: EVENT_DESC.tontinatorSex,
              },
            ]}
          />
          <TontinatorInputs
            skipComparePlanRangeAdjustment
            formData={incomeForecastParams}
            contributionSliders
            retirementSliders
            setFormData={setIncomeForecastParams}
            trackingActivityCurrentAgeSlider={{
              incBtnId: 'tontinator_slider_increment_age',
              decBtnId: 'tontinator_slider_decrement_age',
              incBtnBoxId: 'tontinator_slider_increment_age_box',
            }}
            trackingActivityRetirementSlider={{
              incBtnId: 'tontinator_slider_increment_income_start_age',
              decBtnId: 'tontinator_slider_decrement_income_start_age',
              incBtnBoxId: 'tontinator_slider_increment_income_start_age_box',
            }}
            trackActivityOneTimeContribution={{
              incBtnId: 'tontinator_slider_increment_onetime_contribution',
              decBtnId: 'tontinator_slider_decrement_onetime_contribution',
              incBtnBoxId: 'tontinator_slider_increment_onetime_box',
            }}
            trackCurrenAgeRangeActivity={{
              sliderId: 'tontinator_slider_age',
            }}
            trackRetAgeRangeActivity={{
              sliderId: 'tontinator_slider_income_start_age',
            }}
            trackRangeOneTime={{
              sliderId: 'tontinator_slider_onetime_contribution',
            }}
            trackActivityMonthlyTarget={{
              sliderId: 'tontinator_slider_monthly_target',
            }}
          />
        </>
      )}
    </section>
  )
}

PublicTontinatorInputs.propTypes = {
  incomeForecastParams: PropTypes.object,
  setIncomeForecastParams: PropTypes.func,
  comparison: PropTypes.bool,
  blueForecastParams: PropTypes.object,
  setBlueForecastParams: PropTypes.func,
  yellowForecastParams: PropTypes.object,
  setYellowForecastParams: PropTypes.func,
}

export default PublicTontinatorInputs
