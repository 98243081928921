import actieiconbottomnaaccount from './../assets/active-icon-bottom-nav_account.svg'
import actieiconbottomnafaq from './../assets/active-icon-bottom-nav_faq.svg'
import actieiconbottomnamt from './../assets/active-icon-bottom-nav_mt.svg'
import chartthumb from './../assets/chart-thumb.svg'
import iconaccountaddbank from './../assets/icon-account_add-bank.svg'
import iconaccountaddreUS from './../assets/icon-account_address-US.svg'
import iconaccountaddrewhiteUS from './../assets/icon-account_address_white-US.svg'
import iconaccountbankadded from './../assets/icon-account_bank-added.svg'
import iconaccountcalendarlihtblue from './../assets/icon-account_calendar-light-blue.svg'
import iconaccountcelebratin from './../assets/icon-account_celebrating.svg'
import iconaccountcheckedreencirclemall from './../assets/icon-account_checked-green-circle_small.svg'
import iconaccountcheckedtoatmeae from './../assets/icon-account_checked-toast-message.svg'
import iconaccountcloecirclemall from './../assets/icon-account_close-circle_small.svg'
import iconaccountcloequare from './../assets/icon-account_close-square.svg'
import iconaccountcloetoatmeae from './../assets/icon-account_close-toast-message.svg'
import iconaccountcontributionbicolor from './../assets/icon-account_contributions-bicolor.svg'
import iconaccountcontributionbicolorUS from './../assets/icon-account_contributions-bicolor-US.svg'
import iconaccountcopy from './../assets/icon-account_copy.svg'
import iconaccountdocdenied from './../assets/icon-account_doc-denied.svg'
import iconaccounteditlihtblue from './../assets/icon-account_edit-light-blue.svg'
import iconaccountemail from './../assets/icon-account_email.svg'
import iconaccounterrorcirclelare from './../assets/icon-account_error-circle_large.svg'
import iconaccountfacecanintruction from './../assets/icon-account_face-scan-instructions.svg'
import iconaccountfundbank from './../assets/icon-account_fund-bank.svg'
import iconaccountidcan from './../assets/icon-account_id-scan.svg'
import iconaccountiderify from './../assets/icon-account_id-verify.svg'
import iconaccountimae30 from './../assets/icon-account_image-30.svg'
import iconaccountinfocirclemall from './../assets/icon-account_info-circle_small.svg'
import iconaccountinforedtrianle from './../assets/icon-account_info-red-triangle.svg'
import iconaccountinfotrianlemall from './../assets/icon-account_info-triangle_small.svg'
import iconaccountinfotrianle from './../assets/icon-account_info-triangle.svg'
import iconaccountlimitedacce from './../assets/icon-account_limited-access.svg'
import iconaccountmarkedquare from './../assets/icon-account_marked-square.svg'
import iconaccountmenuaatarhape from './../assets/icon-account-menu_avatar-shape.svg'
import iconaccountmenucontributionhitory from './../assets/icon-account-menu_contribution-history.svg'
import iconaccountmenudeleteaccount from './../assets/icon-account-menu_delete-account.svg'
import iconaccountmenufacecan from './../assets/icon-account-menu_face-scan.svg'
import iconaccountmenufundyourpenion from './../assets/icon-account-menu_fund-your-pension.svg'
import iconaccountmenufundyourpenionUS from './../assets/icon-account-menu_fund-your-pension-US.svg'
import iconaccountmenuhomeaddre from './../assets/icon-account-menu_home-address.svg'
import iconaccountmenuiniteyourfriend from './../assets/icon-account-menu_invite-your-friends.svg'
import iconaccountmenulanuae from './../assets/icon-account-menu_languages.svg'
import iconaccountmenuloinhitory from './../assets/icon-account-menu_login-history.svg'
import iconaccountmenuloouticon from './../assets/icon-account-menu_logout-icon.svg'
import iconaccountmenunotification from './../assets/icon-account-menu_notifications.svg'
import iconaccountmenupayout from './../assets/icon-account-menu_payout.svg'
import iconaccountmenuperonaldetail from './../assets/icon-account-menu_personal-details.svg'
import iconaccountmenupin from './../assets/icon-account-menu_pin.svg'
import iconaccountmenupriacypolicy from './../assets/icon-account-menu_privacy-policy.svg'
import iconaccountmenutermcondition from './../assets/icon-account-menu_terms-conditions.svg'
import iconaccountnocontribution from './../assets/icon-account_no-contributions.svg'
import iconaccountpaidout from './../assets/icon-account_paid-out.svg'
import iconaccountpaidoutUS from './../assets/icon-account_paid-out-US.svg'
import iconaccountpendin from './../assets/icon-account_pending.svg'
import iconaccountpendinUS from './../assets/icon-account_pending-US.svg'
import iconaccountredeemed from './../assets/icon-account_redeemed.svg'
import iconaccountredeemedUS from './../assets/icon-account_redeemed-US.svg'
import iconaccountrewardbicolor from './../assets/icon-account_rewards-bicolor.svg'
import iconaccountrewardlihtblue from './../assets/icon-account_rewards-light-blue.svg'
import iconaccountadface from './../assets/icon-account_sad-face.svg'
import iconaccountendinairplane from './../assets/icon-account_sending-airplane.svg'
import iconaccounttranaction from './../assets/icon-account_transactions.svg'
import iconaccountunmarkedcirclemall from './../assets/icon-account_unmarked-circle_small.svg'
import iconaccountunmarkedquare from './../assets/icon-account_unmarked-square.svg'
import iconbottomnaaccountactienotif from './../assets/icon-bottom-nav_account-active-notif.svg'
import iconbottomnaaccountnotification from './../assets/icon-bottom-nav_account-notification.svg'
import iconbottomnaaccount from './../assets/icon-bottom-nav_account.svg'
import iconbottomnafaqactienotif from './../assets/icon-bottom-nav_faq-active-notif.svg'
import iconbottomnafaqnotification from './../assets/icon-bottom-nav_faq-notification.svg'
import iconbottomnafaq from './../assets/icon-bottom-nav_faq.svg'
import iconbottomnamtactienotif from './../assets/icon-bottom-nav_mt-active-notif.svg'
import iconbottomnamtnotification from './../assets/icon-bottom-nav_mt-notification.svg'
import iconbottomnamt from './../assets/icon-bottom-nav_mt.svg'
import iconcontributionUS from './../assets/icon-contributions-US.svg'
import iconcurrencyUS from './../assets/icon-currency-US.svg'
import icondoublearrowback from './../assets/icon-double_arrow-back.svg'
import icondoublearrowforward from './../assets/icon-double_arrow-forward.svg'
import iconmtaddray from './../assets/icon-mt_add-gray.svg'
import iconmtaddwhite from './../assets/icon-mt_add-white.svg'
import iconmtarrowwhite from './../assets/icon-mt_arrow-white.svg'
import iconmtaatarhape from './../assets/icon-mt_avatar-shape.svg'
import iconmtcalculator from './../assets/icon-mt_calculator.svg'
import iconmtcloequare from './../assets/icon-mt_close-square.svg'
import iconmteditray from './../assets/icon-mt_edit-gray.svg'
import iconmteditwhite from './../assets/icon-mt_edit-white.svg'
import iconmtexpectedbalance from './../assets/icon-mt_expected-balance.svg'
import iconmtincreae from './../assets/icon-mt_increase.svg'
import iconmtinfocirclelare from './../assets/icon-mt_info-circle_large.svg'
import iconmtinfolihtblue from './../assets/icon-mt_info-light-blue.svg'
import iconmtinfotrianlemall from './../assets/icon-mt_info-triangle_small.svg'
import iconmtiniht from './../assets/icon-mt_insight.svg'
import iconmtnominalbalance from './../assets/icon-mt_nominal-balance.svg'
import iconmtnominalbalanceUS from './../assets/icon-mt_nominal-balance-US.svg'
import iconmtprojectedincomelihtblue from './../assets/icon-mt_projected-income_light-blue.svg'
import iconmtprojectedincome from './../assets/icon-mt_projected-income.svg'
import iconmtreferral from './../assets/icon-mt_referral.svg'
import iconmttoleoff from './../assets/icon-mt_toggle-off.svg'
import iconmttoleon from './../assets/toggleOn.svg'
import iconmttontinatorlihtblue from './../assets/icon-mt_tontinator_light-blue.svg'
import iconmttontinator from './../assets/icon-mt_tontinator.svg'
import iconmytontineloo from './../assets/icon-mytontine-logo.svg'
import iconnextpayoutcard from './../assets/icon-next-payout-card.svg'
import iconnotlikelyemojiurey from './../assets/icon_not-likely-emoji_survey.svg'
import icononboardinarrowbackheader from './../assets/icon-onboarding_arrow-back-header.svg'
import icononboardinarrowback from './../assets/icon-onboarding_arrow-back.svg'
import icononboardinarrowdownblue from './../assets/icon-onboarding_arrow-down-blue.svg'
import icononboardinarrowdownray from './../assets/icon-onboarding_arrow-down-gray.svg'
import icononboardinarrowdown from './../assets/icon-onboarding_arrow-down.svg'
import icononboardinarrowforward from './../assets/icon-onboarding_arrow-forward.svg'
import icononboardinarrowupblue from './../assets/icon-onboarding_arrow-up-blue.svg'
import icononboardinarrowupray from './../assets/icon-onboarding_arrow-up-gray.svg'
import icononboardinarrowup from './../assets/icon-onboarding_arrow-up.svg'
import icononboardinarrowupwhite from './../assets/icon-onboarding_arrow-up-white.svg'
import icononboardinfemale from './../assets/icon-onboarding_female.svg'
import icononboardinfemalewhite from './../assets/icon-onboarding_female-white.svg'
import icononboardinhappyundektop from './../assets/icon-onboarding_happy-sun-desktop.svg'
import icononboardinhappyun from './../assets/icon-onboarding_happy-sun.svg'
import icononboardinhappyunwhite from './../assets/icon-onboarding_happy-sun-white.svg'
import icononboardinindicator1 from './../assets/icon-onboarding_indicator-1.svg'
import icononboardinindicator2 from './../assets/icon-onboarding_indicator-2.svg'
import icononboardinindicator3 from './../assets/icon-onboarding_indicator-3.svg'
import icononboardininfocirclelare from './../assets/icon-onboarding_info-circle_large.svg'
import icononboardininfocirclemall from './../assets/icon-onboarding_info-circle_small.svg'
import icononboardininfotrianle from './../assets/icon-onboarding_info-triangle.svg'
import icononboardinmale from './../assets/icon-onboarding_male.svg'
import icononboardinmalewhite from './../assets/icon-onboarding_male-white.svg'
import icononboardinlider from './../assets/icon-onboarding_slider.svg'
import icononboardintep1 from './../assets/icon-onboarding_step-1.svg'
import icononboardintep2 from './../assets/icon-onboarding_step-2.svg'
import icononboardinwhitearrowback from './../assets/icon-onboarding_white-arrow-back.svg'
import iconpayoutUS from './../assets/icon-payouts-US.svg'
import iconmileureymodal from './../assets/icon_smile-survey-modal.svg'
import icontranactionUS from './../assets/icon-transactions-US.svg'
import iconerylikelyemojiurey from './../assets/icon_very-likely-emoji_survey.svg'
import infoamber from './../assets/info-amber.svg'
import OnboardinSlider from './../assets/OnboardingSlider.svg'
import step1 from './../assets/step1.svg'
import step2 from './../assets/step2.svg'
import step3 from './../assets/step3.svg'
import yellowShield from '../assets/yellowShield.svg'
import infoIconSmall from '../assets/infoIconSmall.svg'
import bdayCake from '../assets/bdaycake.svg'
import blog from '../assets/blog.svg'
import dashboardPlaceholder from '../assets/placeholder-dashboard.svg'
import yellowQuestionMark from '../assets/yellowQuestion.svg'
import yellowClock from '../assets/yellowClock.svg'
import infoCircle from '../assets/infocircle.svg'
import deleteAccount from '../assets/closeAccount.svg'
import chevronBlueLeft from '../assets/chevronBlueLeft.svg'
import totalReferralCount from '../assets/totalreferralsicon.svg'
import incetive from '../assets/maxincentiveicon.svg'
import error from '../assets/error.svg'
import sittingTontine from '../assets/sitting-tontine.svg'
import whiteTrend from '../assets/white-trend.svg'
import smsRounded from '../assets/sms-svgrepo-com.svg'
import happyTontine from '../assets/happy-tontine.svg'
import happyTontineWhite from '../assets/happy-tontine-white.svg'
import happyTontineWhite1 from '../assets/happy-tontine-white-1.svg'
import logoTontineTrust from '../assets/tontine-trust-blue-logo.svg'
import badgeGooglePlay from '../assets/badge-google-play.svg'
import badgeAppleStore from '../assets/badge-apple-store.svg'
import logoGoogleChrome from '../assets/logo-google-chrome.svg'
import logoMozillaFirefox from '../assets/logo-mozilla-firefox.svg'
import logoOpera from '../assets/logo-opera.svg'
import logoSafari from '../assets/logo-safari.svg'
import incentiveWhite from '../assets/incentiveWhite.svg'
import referredWhite from '../assets/referredWhite.svg'
import tontiRaisedHands from '../assets/tontileft.svg'
import tontiHappy from '../assets/happy-tontine.svg'

export const ASSET = {
  tontiRaisedHands,
  tontiHappy,
  incentiveWhite,
  referredWhite,
  happyTontine,
  happyTontineWhite,
  happyTontineWhite1,
  smsRounded,
  whiteTrend,
  sittingTontine,
  error,
  totalReferralCount,
  incetive,
  chevronBlueLeft,
  deleteAccount,
  infoCircle,
  yellowClock,
  yellowQuestionMark,
  dashboardPlaceholder,
  blog,
  bdayCake,
  infoIconSmall,
  yellowShield,
  actieiconbottomnaaccount,
  actieiconbottomnafaq,
  actieiconbottomnamt,
  chartthumb,
  iconaccountaddbank,
  iconaccountaddreUS,
  iconaccountaddrewhiteUS,
  iconaccountbankadded,
  iconaccountcalendarlihtblue,
  iconaccountcelebratin,
  iconaccountcheckedreencirclemall,
  iconaccountcheckedtoatmeae,
  iconaccountcloecirclemall,
  iconaccountcloequare,
  iconaccountcloetoatmeae,
  iconaccountcontributionbicolor,
  iconaccountcontributionbicolorUS,
  iconaccountcopy,
  iconaccountdocdenied,
  iconaccounteditlihtblue,
  iconaccountemail,
  iconaccounterrorcirclelare,
  iconaccountfacecanintruction,
  iconaccountfundbank,
  iconaccountidcan,
  iconaccountiderify,
  iconaccountimae30,
  iconaccountinfocirclemall,
  iconaccountinforedtrianle,
  iconaccountinfotrianlemall,
  iconaccountinfotrianle,
  iconaccountlimitedacce,
  iconaccountmarkedquare,
  iconaccountmenuaatarhape,
  iconaccountmenucontributionhitory,
  iconaccountmenudeleteaccount,
  iconaccountmenufacecan,
  iconaccountmenufundyourpenion,
  iconaccountmenufundyourpenionUS,
  iconaccountmenuhomeaddre,
  iconaccountmenuiniteyourfriend,
  iconaccountmenulanuae,
  iconaccountmenuloinhitory,
  iconaccountmenuloouticon,
  iconaccountmenunotification,
  iconaccountmenupayout,
  iconaccountmenuperonaldetail,
  iconaccountmenupin,
  iconaccountmenupriacypolicy,
  iconaccountmenutermcondition,
  iconaccountnocontribution,
  iconaccountpaidout,
  iconaccountpaidoutUS,
  iconaccountpendin,
  iconaccountpendinUS,
  iconaccountredeemed,
  iconaccountredeemedUS,
  iconaccountrewardbicolor,
  iconaccountrewardlihtblue,
  iconaccountadface,
  iconaccountendinairplane,
  iconaccounttranaction,
  iconaccountunmarkedcirclemall,
  iconaccountunmarkedquare,
  iconbottomnaaccountactienotif,
  iconbottomnaaccountnotification,
  iconbottomnaaccount,
  iconbottomnafaqactienotif,
  iconbottomnafaqnotification,
  iconbottomnafaq,
  iconbottomnamtactienotif,
  iconbottomnamtnotification,
  iconbottomnamt,
  iconcontributionUS,
  iconcurrencyUS,
  icondoublearrowback,
  icondoublearrowforward,
  iconmtaddray,
  iconmtaddwhite,
  iconmtarrowwhite,
  iconmtaatarhape,
  iconmtcalculator,
  iconmtcloequare,
  iconmteditray,
  iconmteditwhite,
  iconmtexpectedbalance,
  iconmtincreae,
  iconmtinfocirclelare,
  iconmtinfolihtblue,
  iconmtinfotrianlemall,
  iconmtiniht,
  iconmtnominalbalance,
  iconmtnominalbalanceUS,
  iconmtprojectedincomelihtblue,
  iconmtprojectedincome,
  iconmtreferral,
  iconmttoleoff,
  iconmttoleon,
  iconmttontinatorlihtblue,
  iconmttontinator,
  iconmytontineloo,
  iconnextpayoutcard,
  iconnotlikelyemojiurey,
  icononboardinarrowbackheader,
  icononboardinarrowback,
  icononboardinarrowdownblue,
  icononboardinarrowdownray,
  icononboardinarrowdown,
  icononboardinarrowforward,
  icononboardinarrowupblue,
  icononboardinarrowupray,
  icononboardinarrowup,
  icononboardinarrowupwhite,
  icononboardinfemale,
  icononboardinfemalewhite,
  icononboardinhappyundektop,
  icononboardinhappyun,
  icononboardinhappyunwhite,
  icononboardinindicator1,
  icononboardinindicator2,
  icononboardinindicator3,
  icononboardininfocirclelare,
  icononboardininfocirclemall,
  icononboardininfotrianle,
  icononboardinmale,
  icononboardinmalewhite,
  icononboardinlider,
  icononboardintep1,
  icononboardintep2,
  icononboardinwhitearrowback,
  iconpayoutUS,
  iconmileureymodal,
  icontranactionUS,
  iconerylikelyemojiurey,
  infoamber,
  OnboardinSlider,
  step1,
  step2,
  step3,
  logoTontineTrust,
  badgeGooglePlay,
  badgeAppleStore,
  logoGoogleChrome,
  logoMozillaFirefox,
  logoOpera,
  logoSafari,
} as const
