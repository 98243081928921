import { useTranslate } from '../../hooks/useTranslate'
import { TrackActivity } from '../Common.types'
import SelectValue from './SelectValue'

/**
 * Renders buttons with `deposit` and `target` values
 */
const SelectTarget = ({
  target,
  setTarget,
  trackActivities,
}: {
  target: 'deposit' | 'target'
  setTarget: (target: 'deposit' | 'target') => void
  /**
   * Index 0 for the array is deposit
   */
  trackActivities?: Array<TrackActivity>
}) => {
  const t = useTranslate()
  return (
    <SelectValue
      label={t('TARGET_INCOME_LABEL')}
      optional
      value={target}
      setValue={setTarget}
      optionsToSelect={['deposit', 'target']}
      buttonLabels={[t('INITIAL_DEPOSIT_VALUE'), t('MONTHLY_TARGET_LABEL')]}
      trackActivities={trackActivities}
      variant="blue-dark"
    />
  )
}

export default SelectTarget
