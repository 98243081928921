import { CONSTANTS } from '../constants/ConstantValues'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { numberFormatter } from '../utils/TSUtilFunctions'
import { getCountryInformation } from '../utils/UtilFunctions'

const PERCENT_DIGITS_FORMATTING = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
}

const CURRENCY_DIGITS_FORMATTING = {
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 1,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}

/**
 * Provides functions for formatting number values and other localization
 * options based on user residency for now. This is a soft "settings" feature
 * which will scale into a settings page where the user would be able to save
 * settings on how they want values in the app to be formatted.
 */
export const useLocalization = () => {
  const {
    context: { user_details },
  } = useAccountService()

  /**
   * Formats a number based on residency from `user_details` and passed in `style`
   * and returns a formatted string with a currency symbol. Passed in params have
   * priority over `user_details`.
   */
  const formatAmount = ({
    amount = 0,
    residency,
    currency,
    style,
    notation,
    digits,
  }: {
    amount: number | bigint
    residency?: string
    currency?: string
    style?: 'percent' | 'currency'
    notation?: 'standard' | 'engineering' | 'compact' | 'scientific'
    digits?: {
      minimumFractionDigits?: number
      maximumFractionDigits?: number
      maximumSignificantDigits?: number
      minimumSignificantDigits?: number
    }
  }):
    | {
        formattedAmountWithSymbol: string
        symbol: string
        formattedAmount: string
      }
    | undefined => {
    try {
      const userCountryInformation = getCountryInformation(
        'alpha3',
        user_details?.residency ?? residency ?? CONSTANTS.FALLBACK_COUNTRY_CODE
      )

      let amountToFormat = amount

      if (typeof amount === 'bigint') {
        //FIXME: Converting bigint to Number can lose precision
        //But we do not have a use case where we use big int
        //This will be fine for a while
        amountToFormat = Number(amount)
        if (style === 'percent') {
          amountToFormat /= 100
        }
      } else if (typeof amount === 'number') {
        amountToFormat = style === 'percent' ? amount / 100 : amount
      } else {
        throw new TypeError(
          `Amount is not a number or bigint got >>${amount as string}<<`
        )
      }

      const formattedAmountWithSymbol = numberFormatter(
        amountToFormat,
        userCountryInformation?.default_locale,
        {
          currency: currency ?? userCountryInformation?.currency,
          style,
          notation,
          ...digits,
        }
      )
      //USA want to see the decimals
      const symbol = formattedAmountWithSymbol.replace(/[\d.,]/g, '').trim()

      const formattedAmount = numberFormatter(
        amountToFormat,
        userCountryInformation?.default_locale,
        {
          style: 'decimal',
          notation,
        }
      )

      return {
        formattedAmountWithSymbol,
        symbol,
        formattedAmount,
      }
    } catch (error) {
      console.error(error)
    }

    return undefined
  }

  /**
   * Formats tontinator returns with abstracted settings
   */
  const formatTontinatorAmount = ({
    amount,
    style,
    currency,
  }: {
    amount: number
    style: 'percent' | 'currency'
    currency: string
  }) => {
    if (amount) {
      const isPercentage = style === 'percent'
      return (
        formatAmount({
          amount: isPercentage ? amount : Math.trunc(amount),
          style,
          currency,
          digits: isPercentage
            ? PERCENT_DIGITS_FORMATTING
            : CURRENCY_DIGITS_FORMATTING,
        })?.formattedAmountWithSymbol ?? amount
      )
    }

    return ''
  }

  return {
    formatAmount,
    formatTontinatorAmount,
  }
}
